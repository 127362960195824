import React from "react";
import { Layout } from "../../../components/Layout";
import { Hero } from "../../../components/Hero";
import { Paragraph } from "../../../components/Paragraph";
import { Gallery } from "../../../components/Gallery";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import SEO from "../../../components/SEO";

export const NFTGeneratorBlog = ({ data }) => (
  <Layout>
    <SEO title="NFT Generator" description="Programmatically generating NFTs" />
    <div className="post-container post-full">
      <Hero
        title="What's up with NFTs"
        backgroundImageName={data.nftBanner.publicURL}
      />
      <main className="post-content-container">
        <Paragraph>
          <h1>NFTs are the new craze</h1>
          <span>
            No matter where you live or who you are, you have probably heard of
            NFTs by now.
          </span>
        </Paragraph>
        <Paragraph>
          <span>
            I never paid much attention to it. I had a good idea of what NFTs
            were and how they worked from coming across it on Twitter or Tech
            articles, but it wasn't enough to hook me in.
          </span>
        </Paragraph>
        <Paragraph>
          <span>
            After browsing some of the NFT marketplaces recently, I noticed how
            a big part of available NFTs were images of randomly generated
            characters. This made sense -- if you were looking to generated
            hundreds, thousands, or tens of thousands of NFTs, there was no
            easier way than randomly generated them.
          </span>
        </Paragraph>
        <Paragraph>
          <span>
            Now, you could create images of random colors and shapes but that
            was no fun. There was no character or personality to it. So the
            question then is:{" "}
            <u>how do you make randomly generated content appealing?</u>
          </span>
        </Paragraph>
        <Paragraph>
          <span>
            The answer is: <u>you give them hats.</u> And clothing. And cool
            hairstyles. And horns. And you make them blue. Then you make them
            only have some features. Some are aliens, some are monkeys, some are
            humans. Some have rare features; some have common features.
          </span>
        </Paragraph>
        <Paragraph>
          <span>
            This was the side of NFTs that finally got my attention. Creating
            randomly generated NFTs using "pre-made parts" seemed like it could
            be a fun challenge to tackle. So, I tried.
          </span>
        </Paragraph>
        <br />
        <br />
        <Paragraph>
          <span>
            There were two parts to this adventure:
            <ul>
              <li>
                1. Create the underlying image assets that would be used to
                create the NFTs.
              </li>
              <li>
                2. Create the script that would stitch the image assets
                together.
              </li>
            </ul>
          </span>
        </Paragraph>
        <Paragraph>
          There was just one problem -- I can't draw to save my life. I also
          know I didn't want to make an actual character, I wanted to do
          something different. It was around Christmas time, so inherently, I
          decided on Christmas Trees. This was my attempt:
        </Paragraph>
        <Gallery>
          <Img
            fluid={data.procreateLayers.childImageSharp.fluid}
            alt="Concept of Default Blog View"
          ></Img>
        </Gallery>
        <Paragraph>
          With that, the first part was done. The basic assets had been created:
          <ul>
            <li>Multiple background colors</li>
            <li>Base trunk</li>
            <li>Multiple foliage colors and patterns</li>
            <li>Multiple tinsel colors and variations</li>
            <li>Multiple ornament options and locations</li>
            <li>Multiple star options</li>
          </ul>
        </Paragraph>
        <Paragraph>
          The only part left now was creating the script to stitch it all
          together. I decided to write it on Python since I've been trying to
          get more familiar with it.
        </Paragraph>
        <Paragraph>The script was divided in two parts.</Paragraph>
        <Paragraph>
          The first part created the "skeleton" of the Christmas Tree. In other
          words, a randomly generated string that determined what type of tree
          it would be--what kind of foliage, what tinsel arrangemnt it would
          have, which ornaments, etc.
        </Paragraph>
        <Paragraph>
          The second part was to actually generate the tree image. I used{" "}
          <Link href="https://pillow.readthedocs.io/en/stable/index.html">
            Pillow
          </Link>
          , a Python Image Library, to load each asset and stitch it together
          according to each tree's "metadata". This is the end result:
        </Paragraph>
        <Gallery>
          <div />
          <Img
            fluid={data.summerNFT.childImageSharp.fluid}
            alt="Concept of Default Blog View"
          ></Img>
          <Img
            fluid={data.springNFT.childImageSharp.fluid}
            alt="Concept of Default Blog View"
          ></Img>
        </Gallery>
        <Gallery>
          <div />
          <Img
            fluid={data.fallNFT.childImageSharp.fluid}
            alt="Concept of Default Blog View"
          ></Img>
          <Img
            fluid={data.winterNFT.childImageSharp.fluid}
            alt="Concept of Default Blog View"
          ></Img>
        </Gallery>
        <Paragraph>
          All in all, this was a fun project. I'm planning on making the code a
          little more flexible so it can be used for more than just Christmas
          Trees. The goal is so the script also exports the attributes of each
          NFT and does the bulk upload to marketplaces. That's for another day.
        </Paragraph>
      </main>
    </div>
  </Layout>
);

export default NFTGeneratorBlog;

export const query = graphql`
  query getNFTGeneratorBlogImagePath {
    procreateLayers: file(
      name: { eq: "procreate-layers" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nftBanner: file(
      name: { eq: "nft-banner" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
    fallNFT: file(
      name: { eq: "fallNFT" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    winterNFT: file(
      name: { eq: "winterNFT" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    springNFT: file(
      name: { eq: "springNFT" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    summerNFT: file(
      name: { eq: "summerNFT" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
