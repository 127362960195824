import React from "react";

export const Gallery = ({ children, wide, fixedHeight }) => (
  <section
    className={
      "post-content gallery " +
      (wide === "true" ? "wide" : "") +
      (fixedHeight === "true" ? "gallery-fixed-height" : "")
    }
  >
    {children}
  </section>
);
